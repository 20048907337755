import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function BookList ({item}) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location =  useLocation();


  const handleOnClickDetail = (data)=>{
    navigate (
      `book/${data.doc.isbn13}`, 
      { state :{
          state : data,
          from : location.pathname,
        }
      }
    );
  }


  // let categories = item?.doc.class_nm.replaceAll(" ","").split(">")


  return (
    <section>
      <div className="book-wrap" key={item?.doc.no}>
        <img src={item?.doc.bookImageURL} alt={item?.doc.bookname} />
        <div className="book-brief">
          <div className="name">{item?.doc.bookname}</div>
          <div className="author">저자: {item?.doc.authors}</div>
          {/* <div className="class">분류: {item?.doc.class_nm}</div> */}
          <div className="isbn13">ISBN: {item?.doc.isbn13}</div>
          <button type="button" onClick={() => handleOnClickDetail(item)}>
            상세보기</button>
        </div>
      </div>
    </section>
  );
}