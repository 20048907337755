import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import SearchForm from "./components/SearchForm";
import BookList from "./components/BookList";
import { ReactComponent as DownAllow } from "./assets/DonwAllow.svg";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import { ReactComponent as Loading } from "./assets/loading.svg";




function App() {
  const [ title, setTitle ] = useState("");
  const [ isFilter, setIsFilter ] =useState([])
  const [ searchToggle, setSearchToggle ] = useState(false)
  const isFetching = useIsFetching()


  const fetcingData = async () =>{
    // const code = '11240';
    // const start = "2024-04-30";
    // const end = "2024-04-01"; 

    try{
      const resposne = await 
      fetch(`https://data4library.kr/api/srchBooks?authKey=${process.env.REACT_APP_LIBRARY}&keyword=${title}&sort=title&exactMatch=true&pageSize=50&format=json`)  
      if(!resposne.ok) {
      throw new Error ('Access successful but serer access failed', resposne.status)
    }

      const data = await resposne.json();
      const dataArray = await data.response.docs
      return dataArray
    }
    catch(error){
        console.error("데이터를 가져오는 데 실패하였습니다.", error)
    }
    finally{
      setSearchToggle(false)
    }

  }


  const { data: libraryQuery, isPending, isLoading } = useQuery({
    queryKey : ['library' ],
    queryFn :fetcingData,
    enabled: searchToggle
  })



const handleFetchCall = (searchKeywordValue) =>{
  setTitle(searchKeywordValue)
  setSearchToggle(true)
}



  return (
    <>
      <section 
        className="search-background"
        style={{
          height : libraryQuery ? "400px" : "100dvh"
        }}
      >

        <p
          style={{
            fontSize:"2rem",
            fontWeight:"700",
            margin:"0",
            color:"#fff"
          }}
        >한라서적</p>

        <p
          style={{
            fontSize:"1.3rem",
            color:"#fff",
          }}
        >"오늘은 어떤 책을 읽어 볼까요?"</p>
        <div className="search-wrap">
          <SearchForm
            title={title}
            setTitle={setTitle}
            setSearchToggle={setSearchToggle}
            handleFetchCall={handleFetchCall}
            />

        
          <p>국립중앙도서관에서 데이터 정보를 가져옵니다. 일일 한도 500 회</p>
        </div>
        <div className="search-down-allow">
          {libraryQuery &&  <DownAllow />}
        </div>
      </section>


    {/* contents area */}
      <main className="content">
        {/* <Caorusel /> */}

        {/* data Fetching proecessing Popup */}
        {
          isFetching ?  (
            <div className="search-loader-wrap">
              <p><strong>국립중앙도서관 데이터</strong></p>
                국립중앙도서관에서 데이터를 받아오는 중입니다.<br/>데이터 다운 속도가 느린점 양해 부탁드립니다.
              <span>(평균 통신 시간 : 7초 ~13초 이상)</span>
              <Loading className="loader" />
            </div>
          ) :null
        }


          {
            libraryQuery && <p> 전체 개수 : { libraryQuery?.length }</p>
          }
        { 
          libraryQuery && libraryQuery?.map(item => {
          return <BookList key={item.doc.no} item={item} />}
          )
        }
        { libraryQuery?.length >0 && <p style={{textAlign:"center"}}>-검색 결과 끝-</p>}
        {
          libraryQuery !== undefined &&  libraryQuery?.length === 0 && <h2>제공된 데이터가 없습니다.</h2>
        }
      </main>
        </>
  );
}

export default App;
