import React, { useState } from "react";
import { IoSearch} from "react-icons/io5";


export default function SearchForm({ title ,setTitle, handleResetBtn, setSearchToggle, handleFetchCall }) {
  const [searchValue, setSearchValue] = useState('');

  
  const handleSubmit =(e) =>{
    e.preventDefault();


    handleFetchCall(searchValue)
  
    setSearchValue("")

  }

  const handleTextChange = (e) =>{
    setSearchValue(e.target.value)
  }



  return (
    <>
      <form 
        className="form" 
        onSubmit={handleSubmit}
      >

        <input
          aria-label="검색 영역"
          className="search-select"
          value={searchValue || ""}
          onChange={(e)=>handleTextChange(e)}
        />

        <button 
          aria-label="검색 제출 버튼"
          type="submit"
          style={{
            display:"block",
            background:"#b6b6b6",
            padding:"0 1rem",
            color:"#fff",
            borderRadius:"8px",
            fontSize:"1.1rem"
          }}
          disabled={searchValue.length === 0}
        ><IoSearch /></button>


      </form>

    </>
  );
}
